import React from 'react';
import HotelReservation from '../components/HotelDetails/HotelReservation';

const Reservation = () => {
  return (
    <div>
      <HotelReservation />
    </div>
  );
};

export default Reservation;