import React from 'react';

const CustomIcon = ({ name, onClick }) => {
  return (
    <div 
      className="custom-icon-size" 
      onClick={onClick} 
      style={{ 
        cursor: 'pointer', 
        padding: '10px 20px', 
        backgroundColor: 'rgb(255, 255, 255)', 
        border: 'none', 
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', 
        borderRadius: '12px',
        display: 'inline-block',
        textAlign: 'center'
      }}
    >
      {name}
    </div>
  );
};

export default CustomIcon;
