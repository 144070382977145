import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button, Select, Dropdown, Menu, Checkbox } from 'antd';
import HotelCard from "../components/HotelCard/HotelCard.js";
import { BASE_URL } from '../constants.js';
import '../App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchBarLite from '../components/SearchBar/SearchBarLite';
import locations from "../data/locations.json";

const { Option } = Select;

/* const amenitiesList = [
  "pool", "wifi", "spa", "bar", "gym", "security", "beach", "parking", "restaurant", "laundry", "baggage"
]; */

/* const amenitiesDisplayMap = {
  "pool": "Pool",
  "wifi": "WiFi",
  "spa": "Wellness Centre",
  "bar": "Bar",
  "gym": "Gym",
  "security": "24 x 7 Security",
  "beach": "Beach",
  "parking": "Parking",
  "restaurant": "Restaurant",
  "laundry": "Laundry Service",
  "baggage": "Baggage Storage"
}; */

const dayoutOptions = [
  "romance", "friendventure", "work", "layover", "bachelorette", "family", "group", "picnic", "photoshoot", "wellness",
];

export default function SearchPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search).get('query');
  const district = new URLSearchParams(location.search).get('district');
  const packages = new URLSearchParams(location.search).getAll('package');
  const amenities = new URLSearchParams(location.search).getAll('amenity');
  const options = new URLSearchParams(location.search).get('option');

  const [searchTerm, setSearchTerm] = useState(query || '');
  const [districtFilter, setDistrictFilter] = useState(district || '');
  const [packageFilter, setPackageFilter] = useState(packages || []);
  const [amenitiesFilter, setAmenitiesFilter] = useState(amenities || []);
  const [dayoutOptionsFilter, setDayoutOptionsFilter] = useState(options || '');
  const [hotels, setHotels] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [sortOption, setSortOption] = useState('');

  const fetchHotels = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/hotels/limited`);
      const data = await response.json();
      setHotels(data);
      setFilteredHotels(data);
    } catch (error) {
      console.error('Error fetching hotels:', error);
    }
  }, []);

  useEffect(() => {
    fetchHotels();
  }, [fetchHotels]);

  useEffect(() => {
    // Check if the search term matches any district
    const matchedDistrict = locations.districts.find(d => d.name.toLowerCase() === searchTerm.toLowerCase());
    if (matchedDistrict) {
      setDistrictFilter(matchedDistrict.name);
    } else {
      setDistrictFilter('');
    }
  }, [searchTerm]);

  const calculateLowestPrice = (hotel, selectedPackages) => {
    const packagePrices = [];

    if (selectedPackages.includes('dayout') || selectedPackages.length === 0) {
      if (hotel.dayout) {
        const price = hotel.dayout.price;
        const discount = hotel.dayout.discountPercentage;
        packagePrices.push({ originalPrice: price, discountedPrice: price - (price * (discount / 100)), discount });
      }
    }

    if (selectedPackages.includes('highTea') || selectedPackages.length === 0) {
      if (hotel.highTea) {
        const price = hotel.highTea.price;
        const discount = hotel.highTea.discountPercentage;
        packagePrices.push({ originalPrice: price, discountedPrice: price - (price * (discount / 100)), discount });
      }
    }

    if (selectedPackages.includes('dining') || selectedPackages.length === 0) {
      if (hotel.dining) {
        const price = hotel.dining.price;
        const discount = hotel.dining.discountPercentage;
        packagePrices.push({ originalPrice: price, discountedPrice: price - (price * (discount / 100)), discount });
      }
    }

    if (packagePrices.length === 0) {
      return { originalPrice: null, discountedPrice: null, discount: null };
    }

    packagePrices.sort((a, b) => a.discountedPrice - b.discountedPrice);
    return packagePrices[0];
  };

  const handleSearch = useCallback(() => {
    let filtered = hotels;

    if (searchTerm) {
      filtered = filtered.filter(hotel =>
        (hotel.name && hotel.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (hotel.location && hotel.location.district && hotel.location.district.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    if (districtFilter && districtFilter !== '') {
      filtered = filtered.filter(hotel =>
        hotel.location && hotel.location.district && hotel.location.district.toLowerCase().includes(districtFilter.toLowerCase())
      );
    }

    if (packageFilter.length > 0) {
      filtered = filtered.filter(hotel => {
        const hasDayout = packageFilter.includes('dayout') && hotel.dayout;
        const hasDining = packageFilter.includes('dining') && hotel.dining;
        const hasHighTea = packageFilter.includes('highTea') && hotel.highTea;
        return hasDayout || hasDining || hasHighTea;
      });
    }

    if (amenitiesFilter.length > 0) {
      filtered = filtered.filter(hotel =>
        amenitiesFilter.every(amenity => hotel.amenities.includes(amenity))
      );
    }

    if (dayoutOptionsFilter && packageFilter.includes('dayout')) {
      filtered = filtered.filter(hotel =>
        hotel.dayout && (dayoutOptionsFilter === '' || hotel.options.includes(dayoutOptionsFilter))
      );
    }

    // Apply sorting based on the selected sort option
    switch (sortOption) {
      case 'price-low-high':
        filtered.sort((a, b) => {
          const priceA = calculateLowestPrice(a, packageFilter).discountedPrice ?? Number.MAX_VALUE;
          const priceB = calculateLowestPrice(b, packageFilter).discountedPrice ?? Number.MAX_VALUE;
          return priceB - priceA;
        });
        break;
      case 'price-high-low':
        filtered.sort((a, b) => {
          const priceA = calculateLowestPrice(a, packageFilter).discountedPrice ?? Number.MAX_VALUE;
          const priceB = calculateLowestPrice(b, packageFilter).discountedPrice ?? Number.MAX_VALUE;
          return priceA - priceB;
        });
        break;
      case 'discount-high-low':
        filtered.sort((a, b) => {
          const discountA = calculateLowestPrice(a, packageFilter).discount ?? 0;
          const discountB = calculateLowestPrice(b, packageFilter).discount ?? 0;
          return discountB - discountA;
        });
        break;
      case 'name-za':
        filtered.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name-az':
        filtered.sort((a, b) => b.name.localeCompare(a.name));
        break;
      default:
        break;
    }

    setFilteredHotels(filtered);
  }, [searchTerm, districtFilter, packageFilter, amenitiesFilter, dayoutOptionsFilter, hotels, sortOption]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handleDistrictChange = (value) => {
    const districtValue = value === '' ? '' : value;
    setDistrictFilter(districtValue);
    const params = new URLSearchParams(location.search);
    if (districtValue) {
      params.set('district', districtValue);
    } else {
      params.delete('district');
    }
    navigate({ search: params.toString() });
  };

  const handleSearchTermChange = (term) => {
    setSearchTerm(term);
    const params = new URLSearchParams(location.search);
    params.set('query', term);
    navigate({ search: params.toString() });
  };

  const handlePackageChange = (values) => {
    setPackageFilter(values);
    if (!values.includes('dayout')) {
      setDayoutOptionsFilter('');
    }
    const params = new URLSearchParams(location.search);
    params.delete('package');
    values.forEach(pkg => params.append('package', pkg));
    if (!values.includes('dayout')) {
      params.delete('option');
    }
    navigate({ search: params.toString() });
  };

  /* const handleAmenitiesChange = (values) => {
    setAmenitiesFilter(values);
    const params = new URLSearchParams(location.search);
    params.delete('amenity');
    values.forEach(amenity => params.append('amenity', amenity));
    navigate({ search: params.toString() });
  }; */

  const handlePackageCheckboxChange = (checkedValues) => {
    setPackageFilter(checkedValues);
    if (!checkedValues.includes('dayout')) {
      setDayoutOptionsFilter('');
    }
    const params = new URLSearchParams(location.search);
    params.delete('package');
    checkedValues.forEach(pkg => params.append('package', pkg));
    if (!checkedValues.includes('dayout')) {
      params.delete('option');
    }
    navigate({ search: params.toString() });
  };

  const handleDayoutOptionsChange = (value) => {
    setDayoutOptionsFilter(value);
    const params = new URLSearchParams(location.search);
    if (value) {
      params.set('option', value);
    } else {
      params.delete('option');
    }
    navigate({ search: params.toString() });
  };

  const handleResetAll = () => {
    setSearchTerm('');
    setDistrictFilter('');
    setPackageFilter([]);
    setAmenitiesFilter([]);
    setDayoutOptionsFilter('');
    setSortOption('');
    navigate('/search');
  };

  // const handleSortChange = (value) => {
  //   setSortOption(value);
  // };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const menuDistrict = (
    <div className="scrollable-dropdown">
      <Menu onClick={({ key }) => handleDistrictChange(key)}>
        <Menu.Item key="">All</Menu.Item>
        {locations.districts.map(district => (
          <Menu.Item key={district.name}>{district.name}</Menu.Item>
        ))}
      </Menu>
    </div>
  );

  const menuPackages = (
    <div className="scrollable-dropdown">
      <Menu>
        <Menu.Item>
          <Checkbox.Group
            value={packageFilter}
            onChange={handlePackageCheckboxChange}
          >
            <div className="vertical-checkbox-group">
              <Checkbox value="dayout">Day Out</Checkbox>
              <Checkbox value="dining">Dining</Checkbox>
              <Checkbox value="highTea">High Tea</Checkbox>
            </div>
          </Checkbox.Group>
        </Menu.Item>
      </Menu>
    </div>
  );

  const menuDayoutOptions = (
    <div className="scrollable-dropdown">
      <Menu onClick={({ key }) => handleDayoutOptionsChange(key)}>
        <Menu.Item key="">All</Menu.Item>
        {dayoutOptions.map(option => (
          <Menu.Item key={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</Menu.Item>
        ))}
      </Menu>
    </div>
  );

  const mapPackageToDisplay = (selectedPackage) => {
    const mapping = {
      highTea: 'High Tea',
      dayout: 'Day Out',
      dining: 'Dining'
    };
    return mapping[selectedPackage] || selectedPackage;
  };

  /* const sortOptionsMenu = (
    <Menu onClick={({ key }) => handleSortChange(key)}>
      <Menu.Item key="price-high-low">Price: High to Low</Menu.Item>
      <Menu.Item key="price-low-high">Price: Low to High</Menu.Item>
      <Menu.Item key="discount-high-low">Discount: High to Low</Menu.Item>
      <Menu.Item key="name-az">Name: A to Z</Menu.Item>
      <Menu.Item key="name-za">Name: Z to A</Menu.Item>
    </Menu>
  ); */

  return (
    <div className="page">
      <div className="container" style={{ paddingTop: '30px' }}>
        <h2 className="title">Discover Your Getaway</h2>
        <SearchBarLite
          initialSearchTerm={searchTerm}
          onSearch={handleSearchTermChange}
          districtFilter={districtFilter}
          hotels={hotels} // Pass hotels data to SearchBarLite
          fetchHotelsOnMount={false} // Prevent SearchBarLite from fetching hotels
        />
        <div className="desktop-filters">
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col span={8}>
              <Select
                showSearch
                placeholder="District"
                size="large"
                style={{ width: '100%' }}
                onChange={handleDistrictChange}
                value={districtFilter || undefined}
              >
                <Option value="">All</Option>
                {locations.districts.map(district => (
                  <Option key={district.name} value={district.name}>
                    {district.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={8}>
              <Select
                mode="multiple"
                allowClear
                placeholder="Packages"
                size="large"
                style={{ width: '100%' }}
                onChange={handlePackageChange}
                value={packageFilter}
              >
                <Option value="dayout">Day Out</Option>
                <Option value="dining">Dining</Option>
                <Option value="highTea">High Tea</Option>
              </Select>
            </Col>
            {/* <Col span={6}>
              <Select
                mode="multiple"
                allowClear
                placeholder="Filter Amenities"
                size="large"
                style={{ width: '100%' }}
                onChange={handleAmenitiesChange}
                value={amenitiesFilter}
              >
                {amenitiesList.map(amenity => (
                  <Option key={amenity} value={amenity}>
                    {amenitiesDisplayMap[amenity]}
                  </Option>
                ))}
              </Select>
            </Col> */}
            <Col span={8}>
              <Select
                placeholder="Day Out Types"
                size="large"
                style={{ width: '100%' }}
                onChange={handleDayoutOptionsChange}
                value={dayoutOptionsFilter || undefined}
                disabled={!packageFilter.includes('dayout')}
              >
                <Option value="">All</Option>
                {dayoutOptions.map(option => (
                  <Option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
        <div className="mobile-filters">
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col span={8}>
              <Dropdown overlay={menuDistrict} trigger={['click']}>
                <Button className="filter-button">
                  {districtFilter ? truncateText(districtFilter, 10) : 'District'}
                </Button>
              </Dropdown>
            </Col>
            <Col span={8}>
              <Dropdown overlay={menuPackages} trigger={['click']}>
                <Button className="filter-button">
                  {packageFilter.length > 0 ? truncateText(packageFilter.map(mapPackageToDisplay).join(', '), 10) : 'Packages'}
                </Button>
              </Dropdown>
            </Col>
            <Col span={8}>
              <Dropdown overlay={menuDayoutOptions} trigger={['click']} disabled={!packageFilter.includes('dayout')}>
                <Button className="filter-button" disabled={!packageFilter.includes('dayout')}>
                  {packageFilter.includes('dayout') ? (dayoutOptionsFilter ? truncateText(dayoutOptionsFilter.charAt(0).toUpperCase() + dayoutOptionsFilter.slice(1), 10) : 'Types') : 'Types'}
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </div>

        <Row gutter={[16, 16]} style={{ marginTop: '16px', justifyContent: 'flex-end' }}>
          {/* Some values are not correct */}
          {/* <Col>
            <Dropdown overlay={sortOptionsMenu} trigger={['click']}>
              <Button type="default">
                Sort By
              </Button>
            </Dropdown>
          </Col> */}
          <Col>
            <Button type="default" onClick={handleResetAll}>
              Reset
            </Button>
          </Col>
        </Row>
      </div>
      <div className="container" style={{ marginTop: '32px' }}>
        <Row gutter={[16, 24]}>
          {filteredHotels.map((hotel, index) => {
            const { originalPrice, discountedPrice, discount } = calculateLowestPrice(hotel, packageFilter);

            return (
              <Col className="gutter-row" xs={24} sm={12} lg={8} key={index}>
                {originalPrice !== null && discount !== null ? (
                  <HotelCard {...hotel} price={originalPrice} discountPercentage={discount} discountedPrice={discountedPrice} />
                ) : (
                  <HotelCard {...hotel} />
                )}
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}