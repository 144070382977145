import React from 'react';
import { Row, Col, Button, message, Dropdown, Menu } from 'antd';
import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Carousel } from 'react-responsive-carousel';
import ReactStars from 'react-stars';
import { EnvironmentFilled } from '@ant-design/icons';
import { SocialIcon } from 'react-social-icons';
import { MdPerson } from "react-icons/md";
import { FaPhone } from 'react-icons/fa';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton
} from 'react-share';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './index.css';
import HotelPackage from './HotelPackage';
import { AmenityIcon } from '../../utils/getIcons';
import { BASE_URL } from '../../constants.js';

const generateGoogleMapsLink = (placeId) => {
    return `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}`;
};
const locationIconStyle = { color: 'red', marginRight: '8px' };
const linkStyle = { color: '#0056b3', textDecoration: 'none' };

export default function HotelDetails({ images = [], name, address = {}, contactDetails = {}, description, location, amenities = [], packages = [], options = [], price, rating, ratingTotal, _id, reservationsEnabled }) {
    const sortedContactTypes = ['tp', 'whatsapp', 'email', ...Object.keys(contactDetails).filter(type => type !== 'tp' && type !== 'whatsapp' && type !== 'email')];
    const generateHref = (contactType, value) => {
        switch (contactType) {
            case 'whatsapp':
                // Format the number to international format (remove leading zero and add country code)
                const formattedNumber = value.replace(/^0/, '94');
                return `https://wa.me/${formattedNumber}`;
            default:
                return value.startsWith('http') ? value : `https://${value}`;
        }
    };

    const packageOrder = ['dayout', 'dayout_with_room', "overnight", 'high_tea', 'lunch', 'dinner'];

    const groupedPackages = packageOrder.map(category => ({
        category,
        packages: packages
            .filter(pkg => pkg.category === category)
            .sort((a, b) => (a.price - (a.price * (a.discountPercentage / 100))) - (b.price - (b.price * (b.discountPercentage / 100))))
    }));

    const sortedPackages = groupedPackages.reduce((acc, group) => [...acc, ...group.packages], []);

    const getUrl = async (_id) => {
        try {
            const response = await fetch(`${BASE_URL}/shorten-url/${_id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (!data.shortUrl) {
                throw new Error('Shortened URL not found in response');
            }
            return data.shortUrl;
        } catch (error) {
            return `${window.location.origin}/p/${_id}`;
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleCopyUrl = async () => {
        const urlToCopy = await getUrl(_id);
        message.success(urlToCopy);
        copyToClipboard(urlToCopy);
    };

    const handleCopyUrlSimple = () => {
        const hotelUrl = `${window.location.origin}/p/${_id}`;
        navigator.clipboard.writeText(hotelUrl).then(() => {
            message.success('Copied');
        }).catch(err => {
            message.error('Failed to copy');
        });
    };

    const shareUrl = `${window.location.origin}/p/${_id}`;
    const shareTitle = `Check out ${name} on Zeylon Life!`;

    const handleShareClick = () => {
        if (navigator.share) {
            navigator.share({
                title: shareTitle,
                text: `Check out ${name} on Zeylon Life!`,
                url: shareUrl,
            })
            .then(() => message.success('Shared'))
        } else {
            message.error('Not supported.');
        }
    };

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const menu = (
        <Menu>
            <Menu.Item key="facebook">
                <FacebookShareButton url={shareUrl} quote={shareTitle}>
                    <span>Share on Facebook</span>
                </FacebookShareButton>
            </Menu.Item>
            <Menu.Item key="twitter">
                <TwitterShareButton url={shareUrl} title={shareTitle}>
                    <span>Share on X</span>
                </TwitterShareButton>
            </Menu.Item>
            {isMobile && (
                <>
                    <Menu.Item key="whatsapp">
                        <WhatsappShareButton url={shareUrl} title={shareTitle}>
                            <span>Share on WhatsApp</span>
                        </WhatsappShareButton>
                    </Menu.Item>
                    <Menu.Item key="email">
                        <EmailShareButton url={shareUrl} subject={shareTitle} body={`Check out ${name} on Zeylon Life!`}>
                            <span>Share via Email</span>
                        </EmailShareButton>
                    </Menu.Item>
                    <Menu.Item key="native">
                        <Button type="link" onClick={handleShareClick}>
                            <span>Share via ...</span>
                        </Button>
                    </Menu.Item>
                </>
            )}
        </Menu>
    );

    return (
        <div className="hotel-details">
            <Row gutter={[16, 24]} align="middle">
                <Col style={{ flex: 'auto' }}>
                    <div className="rating" style={{ textAlign: 'left' }}>
                        <ReactStars value={rating} size={15} edit={false} color2="#faa612" />
                        <span style={{ marginLeft: '8px' }}>({ratingTotal})</span>
                    </div>
                </Col>
                <Col>
                    <div className="copy-button">
                        <Button 
                            type="default" 
                            id={`copy-url-button-${_id}`} 
                            onClick={handleCopyUrlSimple}
                            icon={<CopyOutlined />}
                            shape="square"
                            size="medium"
                        />
                    </div>
                </Col>
                <Col>
                    <div className="share-button">
                        <Dropdown overlay={menu} placement="bottomRight">
                            <Button
                                type="default"
                                icon={<ShareAltOutlined />}
                                shape="square"
                                size="medium"
                            />
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 24]} align="middle">
                {!isMobile && (
                <Col>
                    <h1 className="title">{name}</h1>
                </Col>
                )}
                {isMobile && (
                <Col>
                    <h2 className="title">{name}</h2>
                </Col>
                )}
            </Row>
            <Row gutter={[16, 24]}>
                <Col sm={24} className="second-row">
                    <div className="second-row">
                        <div>
                            <a href={generateGoogleMapsLink(location.placeId)} target="_blank" rel="noopener noreferrer" style={linkStyle}>
                                <EnvironmentFilled style={locationIconStyle} />
                                {Object.values(address).toString()}
                            </a>
                        </div>
                        {/* <div className="rating">
                            <ReactStars value={rating} size={15} edit={false} color2="#faa612" />
                        </div> */}
                    </div>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col sm={24} md={12} style={{ paddingBottom: 24 }}>
                    <Carousel showThumbs={false} showStatus={false}>
                        {images.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt="" />
                            </div>
                        ))}
                    </Carousel>
                </Col>
                <Col sm={24} md={12}>
                    <Row gutter={8} className="tags">
                        {sortedContactTypes.map((contactType, index) => (
                            contactType === "tp"
                            ? <div className="tag" key={`tp-${index}`}>        
                                <a href={`tel:${contactDetails[contactType][0]}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <FaPhone />{contactDetails[contactType].join(" / ")}
                                </a>
                            </div>
                            : contactType === "whatsapp"
                            ? <div className="tag-icon" key={`whatsapp-${index}`}>
                                <SocialIcon network="whatsapp" url={generateHref(contactType, contactDetails[contactType])} target='_blank_'/>
                            </div>
                            : contactType === "email"
                            ? <div className="tag-icon" key={`email-${index}`}>
                                <SocialIcon url={`mailto:${contactDetails[contactType]}`} target='_blank_'/>
                            </div>
                            : contactType === "googlemaps"
                            ? <div className="tag-icon" key={`googlemaps-${index}`}>
                                {/* <a href={generateHref(contactType, contactDetails[contactType])} target='_blank_'>
                                <img src={"/googlemaps.svg"} alt=""/>
                                </a> */}
                            </div>
                            : contactType === "website"
                            ?  <div className="tag-icon" key={`website-${index}`}>
                                <SocialIcon url={generateHref(contactType, contactDetails[contactType])} network="dribbble" target='_blank_'/>
                            </div>
                            : <div className="tag-icon" key={`${contactType}-${index}`}>
                                <SocialIcon url={generateHref(contactType, contactDetails[contactType])} target='_blank_'/>
                            </div>                            
                        ))}
                    </Row>
                    <Row>
                        <div className="dayout-options">
                            <div className="tags">
                                {options && options.length > 0 ? (
                                    options.map((option, index) => (
                                        <AmenityIcon type={option} key={index} />
                                    ))
                                ) : (
                                    <p>No destinations available</p>
                                )}
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className='description'>
                            {description}
                        </div>
                    </Row>
                </Col>
            </Row>
            <h2 className="title" style={{ paddingTop: '10px', paddingBottom: '5px' }}>Packages</h2>
            {sortedPackages && sortedPackages.length > 0 ? (
                <>
                    {sortedPackages.map(pkg => {
                        const discountedPrice = pkg.price - (pkg.price * (pkg.discountPercentage / 100));
                        return (
                        <HotelPackage
                            hotelName={name}
                            hotelId={_id}
                            hotelContactNumber={contactDetails.tp[0]}
                            hotelEmail={contactDetails.email}
                            key={pkg._id}
                            category={pkg.category}
                            time={`${pkg.checkIn} — ${pkg.checkOut}`}
                            pax={pkg.maxPax}
                            description={pkg.description}
                            amenities={pkg.amenities || []}
                            price={discountedPrice}
                            priceOld={discountedPrice !== pkg.price ? pkg.price : null}
                            priceCaption={pkg.pricing}
                            reservationsEnabled={reservationsEnabled}
                        />
                        );
                    })}
                    <div className="package-disclaimer">
                        <p><MdPerson /> in each package indicates the maximum number of guests allowed per reservation.</p>
                    </div>
                </>
            ) : (
                <div>No packages available</div>
            )}
            <div className="amenities">
                <h2 className="title"> Property Amenities</h2>
                <div className="tags">
                    {amenities.map((type, index) => (
                        <AmenityIcon type={type} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
}