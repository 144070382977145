import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Button, message, Select, Row, Col, Card, DatePicker } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';
import './index.css';
import countryList from 'react-select-country-list';
import { BASE_URL } from '../../constants.js';

const { Option } = Select;

const NICParser = (nic) => {
  nic = nic.toUpperCase();
  const nicType = nic.length === 12;
  if (nic.length !== 10 && nic.length !== 12) {
    throw new Error('Invalid ID number length');
  }

  const year = nicType ? nic.slice(0, 4) : '19' + nic.slice(0, 2);
  let dayOfYear;

  if (nicType) {
    dayOfYear = parseInt(nic.slice(4, 7), 10);
  } else {
    dayOfYear = parseInt(nic.slice(2, 5), 10);
    if (dayOfYear > 500) {
      dayOfYear -= 500;
    }
  }

  let birthDate;
  try {
    birthDate = moment(year, 'YYYY').dayOfYear(dayOfYear);
    if (!birthDate.isValid() || dayOfYear > 366 || dayOfYear < 1) {
      throw new Error('Invalid birth date');
    }
  } catch (e) {
    throw new Error('Birth date not within range.');
  }

  return {
    birthDate: birthDate.toDate(),
  };
};

const isOver18 = (birthDate) => {
  const today = new Date();
  const age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();
  return (age > 18) || (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));
};

const priceCaptionDisplayText = {
    per_room: 'Per Room',
    per_person: 'Per Person',
};

const HotelReservation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hotelName, hotelId, packageName, packagePrice, priceCaption, checkIn, checkOut, maxPax, hotelEmail, hotelContactNumber } = location.state;
  const displayPriceCaption = priceCaptionDisplayText[priceCaption] || priceCaption;

  const [form] = Form.useForm();
  const [otpSent, setOtpSent] = useState(false);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [nationality, setNationality] = useState('');
  const [sessionId, setSessionId] = useState('');

  const options = countryList().getData();

  const sendOtp = async (email) => {
    try {
      const response = await axios.post(`${BASE_URL}/reservation/send-otp`, { email });
      setSessionId(response.data.sessionId);
      message.success('A confirmation code has been sent to your email.');
      setOtpSent(true);
    } catch (error) {
      message.error(`Error sending OTP: ${error.response?.data?.message || error.message}`);
      throw error;
    }
  };

  const verifyOtp = async (email, otp) => {
    try {
      await axios.post(`${BASE_URL}/reservation/verify-otp`, { email, otp, sessionId });
      message.success('OTP verified successfully.');
    } catch (error) {
      message.error(`Error verifying OTP: ${error.response?.data?.message || error.message}`);
      throw error;
    }
  };

  const submitReservation = async (values) => {
    try {
      const reservationData = {
        ...values,
        hotelName,
        hotelId,
        packageName,
        packagePrice,
        displayPriceCaption,
        maxPax,
        hotelEmail,
        hotelContactNumber,
        date: values.date.format('YYYY-MM-DD')
      };

      if (!['High Tea', 'Lunch Buffet', 'Dinner Buffet'].includes(packageName)) {
        reservationData.checkIn = checkIn;
        reservationData.checkOut = checkOut;
      }

      await axios.post(`${BASE_URL}/reservation`, reservationData);
      message.success('Reservation successfully submitted & details emailed!');
      setTimeout(() => {
        navigate(`/p/${hotelId}`, { state: { hotelId, hotelName, packageName, packagePrice, priceCaption, checkIn, checkOut, maxPax, hotelEmail, hotelContactNumber } });
      }, 1000); // 1-second delay
    } catch (error) {
      message.error(`Error submitting reservation: ${error.response?.data?.message || error.message}`);
      throw error;
    }
  };

  const onFinish = async (values) => {
    try {
      if (!otpSent) {
        await sendOtp(values.email);
      } else {
        await verifyOtp(values.email, values.otp);
        await submitReservation(values);
      }
    } catch (error) {
      // Handle errors individually within respective functions
    }
  };

  const generateTimeOptions = (startTime, endTime, packageName) => {
    const options = [];
    let currentTime = new Date(`1970-01-01T${startTime}:00`);
    let end = new Date(`1970-01-01T${endTime}:00`);
    
    if (packageName === "Overnight") {
        end = new Date(`1970-01-01T23:45:00`);
    } else {
        end.setMinutes(end.getMinutes() - 30);
    }

    while (currentTime <= end) {
        options.push(currentTime.toTimeString().substring(0, 5));
        currentTime.setMinutes(currentTime.getMinutes() + 15);
    }
    
    return options;
  };

  const timeOptions = generateTimeOptions(checkIn, checkOut, packageName);

  const disabledDate = (current, packageName) => {
    // Disable past dates and dates more than 1 month in the future
    const today = moment().startOf('day');
    const endDate = moment().add(1, 'month').endOf('day');
    if (!current || current < today || current > endDate) {
      return true;
    }

    // Check if the current time is past the package's checkout time for today
    if (packageName !== "Overnight") {
      if (current.isSame(today, 'day')) {
        const currentTime = moment();
        const checkoutTime = moment(checkOut, 'HH:mm');
        if (currentTime.isAfter(checkoutTime)) {
          return true;
        }
      }
    }

    return false;
  };

  const validatePhoneNumber = (_, value) => {
    const phoneNumber = parsePhoneNumberFromString(value, countryCode.toUpperCase());
    if (phoneNumber && phoneNumber.isValid()) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('Please enter a valid phone number!'));
    }
  };

  const validateIDNumber = (_, value) => {
    if (nationality === 'Sri Lanka') {
      try {
        if (value.length !== 10 && value.length !== 12) {
          return Promise.reject(new Error('ID number must be either 10 or 12 characters long'));
        }
        const nicData = NICParser(value);
        if (!isOver18(nicData.birthDate)) {
          return Promise.reject(new Error('You must be at least 18 years old'));
        }
      } catch (e) {
        return Promise.reject(new Error(e.message));
      }
    }
    return Promise.resolve();
  };

  const handlePhoneChange = (value, data) => {
    setPhone(value);
    setCountryCode(data.countryCode);
  };

  const handleNationalityChange = (value) => {
    setNationality(value);
  };

  return (
    <div className="container reservation-container" style={{ paddingTop: '60px' }}>
      <Row justify="center">
        <Col span={24}>
          <Card className="reservation-card">
            <h1 className="reservation-title">Reservation for {packageName}</h1>
            <div className="reservation-details">
              <p className="reservation-subtitle">Hotel: {hotelName}</p>
              <p className="reservation-subtitle">Price: LKR {Number(packagePrice).toLocaleString()} ({displayPriceCaption})</p>
              {!['High Tea', 'Lunch Buffet', 'Dinner Buffet'].includes(packageName) && (
                <>
                  <p>Check-in: {checkIn}</p>
                  <p>Check-out: {checkOut} {packageName === "Overnight" && "(next day)"}</p>
                </>
              )}
            </div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <fieldset disabled={otpSent}>
                <Form.Item
                  name="date"
                  label="Select Date"
                  rules={[{ required: true, message: 'Please select a date!' }]}
                >
                  <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} disabled={otpSent} />
                </Form.Item>
                <Form.Item
                  name="arrivalTime"
                  label="Arrival Time"
                  rules={[{ required: true, message: 'Please select your arrival time!' }]}
                >
                  <Select placeholder="Select a time" disabled={otpSent}>
                    {timeOptions.map((time) => (
                      <Option key={time} value={time}>
                        {time}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input disabled={otpSent} />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input disabled={otpSent} />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                >
                  <Input disabled={otpSent} />
                </Form.Item>
                <Form.Item
                  name="nationality"
                  label="Nationality"
                  rules={[{ required: true, message: 'Please select your nationality!' }]}
                >
                  <Select
                    showSearch
                    placeholder="Select your nationality"
                    optionFilterProp="children"
                    onChange={handleNationalityChange}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={options.map(country => ({ label: country.label, value: country.label }))}
                    disabled={otpSent}
                  />
                </Form.Item>
                <Form.Item
                  name="mobileNumber"
                  label="Mobile Number"
                  rules={[
                    { required: true, message: 'Please input your mobile number!' },
                    { validator: validatePhoneNumber }
                  ]}
                >
                  <PhoneInput
                    country={'lk'}
                    value={phone}
                    onChange={handlePhoneChange}
                    inputStyle={{ width: '100%', borderRadius: '4px', border: '1px solid #d9d9d9' }}
                    enableSearch={true}
                    disableCountryCode={false}
                    disableDropdown={false}
                    countryCodeEditable={false}
                    preferredCountries={['lk', 'us', 'gb']}
                    containerStyle={{ width: '100%' }}
                    disabled={otpSent}
                  />
                </Form.Item>
                <Form.Item
                  name="id"
                  label="ID/Passport No"
                  rules={[
                    { required: true, message: 'Please input your identity card number!' },
                    { validator: validateIDNumber }
                  ]}
                >
                  <Input disabled={otpSent} />
                </Form.Item>
                <Form.Item
                  name="people"
                  label={`Number of Guests (Max: ${maxPax})`}
                  rules={[
                    { required: true, message: 'Please input the number of guests!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value > 0 && value <= maxPax) {
                          return Promise.resolve();
                        } else if (value) {
                          return Promise.reject(new Error(`Up to ${maxPax} guests allowed!`));
                        }
                        return Promise.reject(new Error('Please input a valid number of guests!'));
                      },
                    }),
                  ]}
                >
                  <Input type="number" disabled={otpSent} />
                </Form.Item>
              </fieldset>
              {otpSent && (
                <Form.Item
                  name="otp"
                  label="Your Email Verification Code (OTP)"
                  rules={[{ required: true, message: 'Please input the OTP!' }]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit" className="confirm-button">
                  {otpSent ? 'Verify OTP & Submit Reservation' : 'Send OTP'}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HotelReservation;
