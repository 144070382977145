import React from 'react';
import { Row, Col, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FaRegClock } from "react-icons/fa6";
import { MdPerson } from "react-icons/md";
import { AmenityIcon } from '../../utils/getIcons';

const categoryDisplayText = {
    high_tea: 'High Tea',
    dayout_with_room: 'Day Out w/ Room',
    dayout: 'Day Out',
    overnight: 'Overnight',
    lunch: 'Lunch Buffet',
    dinner: 'Dinner Buffet',
};

const priceCaptionDisplayText = {
    per_room: 'Per Room',
    per_person: 'Per Person',
};

export default function HotelPackage({ id, hotelName, hotelId, category, time, pax, description, amenities, price, priceOld, priceCaption, hotelEmail, hotelContactNumber, reservationsEnabled }) {
    const displayCategory = categoryDisplayText[category] || category;
    const displayPriceCaption = priceCaptionDisplayText[priceCaption] || priceCaption;
    const navigate = useNavigate();

    const handleReserveClick = () => {
        message.info('Functionality coming soon. Please contact the hotel directly for reservations.');
        navigate('/reservation', {
            state: {
                hotelId: hotelId,
                hotelName: hotelName,
                hotelEmail: hotelEmail,
                hotelContactNumber: hotelContactNumber,
                packageName: displayCategory,
                packagePrice: price,
                priceCaption: priceCaption,
                checkIn: time.split('—')[0].trim(),
                checkOut: time.split('—')[1].trim(),
                maxPax: pax
            }
        });
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <Row className={`hotel-package ${category}`}>
            <Col sm={24} md={10} className='col title'>
                <div className='first-row'>
                    <div className='category'>                     
                        <h3>{displayCategory}</h3>
                    </div>
                    <div className='time'>
                        <FaRegClock /><h3>{time}</h3> <span>&nbsp;</span> 
                        <div className='time'></div><MdPerson /><h3>{pax}</h3>
                    </div>
                </div>
                <div className='description'>{description}</div>
            </Col>
            <Col sm={24} md={10} className='col'>
                <Row>
                    {amenities.map((contactType, index) => (
                        <Col span={12} key={index} className="amenity">
                            <AmenityIcon type={contactType} style={{ paddingRight: 2 }} />
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col sm={24} md={4} className={`col price ${category}`}>
                <div className='before-price'>Starting from</div>
                {priceOld && (
                    <div className="price-old">LKR {priceOld.toLocaleString()}</div>
                )}
                <div className="price-new">LKR {price.toLocaleString()}</div>
                <div className='price-caption'>{displayPriceCaption.toLocaleString()}</div>
                {reservationsEnabled && (
                    <div className='reserve-button-container'>
                        <Button 
                            type="primary" 
                            className='reserve-button' 
                            onClick={handleReserveClick}
                        >
                            Reserve
                        </Button>
                    </div>
                )}
            </Col>
        </Row>
    );
}
