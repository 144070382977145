import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import HotelCard from "../components/HotelCard/HotelCard.js";
import { Col, Row, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cover from '../components/Cover/Cover.js';
import { BASE_URL } from '../constants.js';
import { DayUse, Steps } from '../components/Widgets/HomeWidgets.js';
import CustomIcon from '../utils/customIcon.js';
import '../App.css';

export default function Home() {
  const [dayoutHotels, setDayoutHotels] = useState([]);
  const [highTeaHotels, setHighTeaHotels] = useState([]);
  const [diningHotels, setDiningHotels] = useState([]);
  const [hotelNames, setHotelNames] = useState([]);
  const navigate = useNavigate();

  const fetchHotelsHome = async () => {
    try {
      const response = await fetch(`${BASE_URL}/hotels/home`);
      const data = await response.json();
      setDayoutHotels(data.hotels.dayoutHotels);
      setHighTeaHotels(data.hotels.highTeaHotels);
      setDiningHotels(data.hotels.diningHotels);
      setHotelNames(data.hotelNames);
    } catch (error) {
      console.error('Error fetching hotels:', error);
    }
  };

  useEffect(() => {
    fetchHotelsHome();
  }, []);

  /* const renderHotelCards = (category) => {
    const filteredHotels = hotels.filter(hotel => {
      if (category === 'dayout') return hotel.dayout;
      if (category === 'highTea') return hotel.highTea;
      if (category === 'dining') return hotel.dining;
      return false;
    });

    const sortedHotels = filteredHotels.sort((a, b) => {
      const discountA = category === 'dayout' ? a.dayout.discountPercentage :
                        category === 'highTea' ? a.highTea.discountPercentage :
                        category === 'dining' ? a.dining.discountPercentage : 0;
      const discountB = category === 'dayout' ? b.dayout.discountPercentage :
                        category === 'highTea' ? b.highTea.discountPercentage :
                        category === 'dining' ? b.dining.discountPercentage : 0;
      return discountB - discountA;
    }).slice(0, 3);

    return (
      <Row gutter={[16, 24]}>
        {sortedHotels.map((hotel, index) => {
          let price, discountPercentage;

          if (category === 'dayout' && hotel.dayout) {
            price = hotel.dayout.price;
            discountPercentage = hotel.dayout.discountPercentage;
          } else if (category === 'highTea' && hotel.highTea) {
            price = hotel.highTea.price;
            discountPercentage = hotel.highTea.discountPercentage;
          } else if (category === 'dining' && hotel.dining) {
            price = hotel.dining.price;
            discountPercentage = hotel.dining.discountPercentage;
          }

          return (
            <Col className="gutter-row" xs={24} sm={12} lg={8} key={index}>
              {price !== undefined && discountPercentage !== undefined ? (
                <HotelCard {...hotel} price={price} discountPercentage={discountPercentage} />
              ) : (
                <HotelCard {...hotel} />
              )}
            </Col>
          );
        })}
      </Row>
    );
  }; */

  const renderHotelCards = (hotels) => (
    <Row gutter={[16, 24]}>
      {hotels.map((hotel, index) => (
        <Col className="gutter-row" xs={24} sm={12} lg={8} key={index}>
          <HotelCard {...hotel} price={hotel.price} discountPercentage={hotel.discountPercentage} />
        </Col>
      ))}
    </Row>
  );

  const districts = ["Colombo", "Kandy", "Galle", "Matara", "Kalutara", "Gampaha", "Anuradhapura", "Trincomalee", "Jaffna", "Nuwara Eliya"];

  const handleViewAllDayoutHotels = () => {
    navigate('/search?package=dayout');
  };

  const handleViewAllHighTeaHotels = () => {
    navigate('/search?package=highTea');
  };

  const handleViewAllDiningHotels = () => {
    navigate('/search?package=dining');
  };

  const handleDistrictClick = (district) => {
    navigate(`/search?query=${district}&district=${district}`);
  };

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
      controls: 1,
      showinfo: 0,
      rel: 0,
      modestbranding: 1
    },
  };

  return (
    <div className="page">
      <Cover 
        title={<div className="break-title">Embrace Every Moment <span className="nowrap">of Your Day</span></div>} 
        description='Experience the cozy comfort & joy in a place where you truly belong' 
        hotels={hotelNames} 
      />
      <div className="after-cover-home">
        <div className="categories">
          <div className="container">
            <div className="widget-heading">
              <div className="title">
                <span onClick={handleViewAllDayoutHotels} style={{ cursor: 'pointer', marginRight: '10px' }}>Day Outs</span>
                .
                <span onClick={handleViewAllHighTeaHotels} style={{ cursor: 'pointer', margin: '0 10px' }}>High Tea</span>
                .
                <span onClick={handleViewAllDiningHotels} style={{ cursor: 'pointer', marginLeft: '10px' }}>Dining</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="widget-block">
            <div className="widget-heading">
              <h2 className="title">Popular Destinations</h2>
              <div className="custom-icon">
                  <div className="tags">
                      {districts.map((district, index) => (
                          <CustomIcon name={district} key={index} onClick={() => handleDistrictClick(district)} />
                      ))}
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="container-home">
              <h2 className="title-home">Day Outs</h2>
              {renderHotelCards(dayoutHotels)}
              <div className="center">
                <div className="viwe-button">
                <Button type="default" size="large" onClick={handleViewAllDayoutHotels} style={{ marginTop: '16px', padding: '10px 20px', backgroundColor: 'rgb(250, 250, 250)', border: 'none', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                    View All
                  </Button>
                </div>
              </div>
            </div>
        </div>
        <div className="container">
          <DayUse/>
        </div>
        <div className="container">
          <div className="widget-block">
            <div className="widget-heading">
              <h2 className="title">Busy Schedule? Indulge in Our High Tea and Dining!</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="container-home">
            <h2 className="title-home">High Tea</h2>
            {renderHotelCards(highTeaHotels)}
            <div className="center">
              <div className="viwe-button">
              <Button type="default" size="large" onClick={handleViewAllDayoutHotels} style={{ marginTop: '16px', padding: '10px 20px', backgroundColor: 'rgb(250, 250, 250)', border: 'none', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                  View All
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="container-home">
            <h2 className="title-home">Dining</h2>
            {renderHotelCards(diningHotels)}
            <div className="center">
              <div className="viwe-button">
              <Button type="default" size="large" onClick={handleViewAllDayoutHotels} style={{ marginTop: '16px', padding: '10px 20px', backgroundColor: 'rgb(250, 250, 250)', border: 'none', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                  View All
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="video-wrapper">
            <YouTube videoId="UiXpMYc39ms" opts={opts} />
          </div>
        </div>
        <div className="container">
          <Steps/>
        </div>
      </div>
    </div>
  );
}  
