import React from 'react';
import { Layout, Row, Col, Space, Divider, Typography } from 'antd';
import { SocialIcon } from 'react-social-icons';
import './index.css';

const { Footer } = Layout;
const { Link, Text } = Typography;

const CustomFooter = () => (
  <Footer style={{ backgroundColor: '#ffffff', padding: '40px 20px' }}>
    <Row justify="center" className="footer-links">
      <Col xs={24} md={12}>
        <Space direction="horizontal" size="large" wrap className="footer-space">
          <Link href="/about" target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>About Us</Link>
          <Link href="/contact" target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>Contact Us</Link>
          <Link href="/disclaimer" target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>Disclaimer</Link>
          <Link href="/privacy" target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>Privacy Policy</Link>
          <Link href="/terms" target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>Terms and Conditions</Link>
        </Space>
      </Col>
    </Row>
    <Divider />
    <Row justify="center" style={{ textAlign: 'center', marginBottom: '20px' }}>
      <Col>
        <Space size="middle">
          <a href="https://www.facebook.com/zeylonlife" target="_blank" rel="noopener noreferrer">
            <SocialIcon url="https://www.facebook.com/zeylonlife" style={{ height: 35, width: 35 }} />
          </a>
          <a href="https://www.instagram.com/zeylonlife" target="_blank" rel="noopener noreferrer">
            <SocialIcon url="https://www.instagram.com/zeylonlife" style={{ height: 35, width: 35 }} />
          </a>
          <a href="https://www.tiktok.com/@zeylonlife" target="_blank" rel="noopener noreferrer">
            <SocialIcon url="https://www.tiktok.com/@zeylonlife" style={{ height: 35, width: 35 }} />
          </a>
          <a href="https://twitter.com/zeylonlife" target="_blank" rel="noopener noreferrer">
            <SocialIcon url="https://twitter.com/zeylonlife" style={{ height: 35, width: 35 }} />
          </a>
          <a href="https://www.youtube.com/@zeylonlife" target="_blank" rel="noopener noreferrer">
            <SocialIcon url="https://www.youtube.com/@zeylonlife" style={{ height: 35, width: 35 }} />
          </a>
          <a href="https://www.linkedin.com/company/zeylonlife" target="_blank" rel="noopener noreferrer">
            <SocialIcon url="https://www.linkedin.com/company/zeylonlife" style={{ height: 35, width: 35 }} />
          </a>
        </Space>
      </Col>
    </Row>
    <Divider />
    <Row justify="center" style={{ textAlign: 'center' }}>
      <Col>
        <Text style={{ color: '#000' }}>© 2024 Zeylon Life. All Rights Reserved.</Text>
      </Col>
    </Row>
  </Footer>
);

export default CustomFooter;