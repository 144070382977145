import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../constants.js';

function RedirectHandler() {
    const { shortUrl } = useParams();

    useEffect(() => {
        const fetchOriginalUrl = async () => {
            console.log(`Fetching original URL for short URL: ${shortUrl}`);
            try {
                const response = await fetch(`${BASE_URL}/shorten-url/r/${shortUrl}`);
                console.log(`Response status: ${response.status}`);
                if (response.ok) {
                    const originalUrl = await response.text();
                    window.location.href = originalUrl;
                } else {
                    console.error('Page not found');
                }
            } catch (error) {
                console.error('Failed to fetch the page', error);
            }
        };
        fetchOriginalUrl();
    }, [shortUrl]);

    return <div>Redirecting...</div>;
}

export default RedirectHandler;
