import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HotelDetails from '../components/HotelDetails/HotelDetails';
// import CoverLite from '../components/Cover/CoverLite';
import { BASE_URL } from '../constants';

export function Hotel() {
  const { hotelId } = useParams(); // Ensure this matches the route parameter
  const [hotel, setHotel] = useState();

  useEffect(() => {
    const fetchHotelById = async () => {
      try {
        const response = await fetch(`${BASE_URL}/hotels/limited/${hotelId}`);
        const data = await response.json();
        setHotel(data);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
    };

    fetchHotelById();
  }, [hotelId]);

  return (
    hotel && (
      <>
       {/* <CoverLite title="Make the most of your day" /> */}
        <div className="container" style={{ paddingTop: '80px' }}>
          <HotelDetails {...hotel} reservationsEnabled={hotel.reservationsEnabled} />
        </div>
      </>
    )
  );
}
