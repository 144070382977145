import React from 'react';
import ReactStars from 'react-stars';
import { EnvironmentFilled } from '@ant-design/icons';
import { Link } from "react-router-dom";
import "./index.css";
import { AmenityIcon } from '../../utils/getIcons';

export default function HotelCard({ images, name, address, amenities, price, rating, ratingTotal, discountPercentage, discountedPrice, _id }) {
  const finalDiscountedPrice = discountedPrice !== undefined ? discountedPrice : price ? price - (price * (discountPercentage / 100)) : null;

  return (
    <Link className="hotel-card" to={`/p/${_id}`}>
      <img alt="example" src={images[0]} />
        <div className="hotel-card-content">
        <div className="description">
          <h3 className="title">{name}</h3>
          <div className="address">
            <EnvironmentFilled className="location" />
            {Object.values(address).join(', ')}
          </div>
          <div className="tags">
            {amenities.slice(0, 3).map((type, index) =>
              <AmenityIcon type={type} key={index} />
            )}
          </div>
          <hr className="divider" />
          <div className="footer">
          <div className="rating">
              <ReactStars value={rating} size={15} edit={false} color2="#faa612" />
              {/* <div className="rate">{rating.toFixed(1)}</div> */}
              <span style={{ marginLeft: '8px' }}>({ratingTotal})</span>
          </div>
            {finalDiscountedPrice !== null && (
              <div className="price">
                {finalDiscountedPrice !== price && <div className="price-old">LKR {price.toLocaleString()}</div>}
                <div className="price-new">LKR {finalDiscountedPrice.toLocaleString()}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}