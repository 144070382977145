import React, { useEffect } from 'react';
import Home from "./pages/home";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
//import HotelNavBar from "./components/NavBar/HotelNavBar";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
//import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Hotel } from "./pages/hotel";
import { Helmet } from 'react-helmet';
import SearchPage from "./pages/search";
import Reservation from './pages/reservation';
import CustomFooter from "./components/Footer/Footer";
import { analytics } from './firebase';
import { logEvent } from "firebase/analytics";
import RedirectHandler from './components/RedirectHandler';

function App() {
  return (
    <Router>
      <AnalyticsHandler />
      <Routes>
        <Route path="/*" element={<MainLayout />} />
      </Routes>
    </Router>
  );
}

function AnalyticsHandler() {
  const location = useLocation();

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', { page_path: location.pathname });
    }
  }, [location]);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-MGP6T605GG', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null;
}

function MainLayout() {
  //const location = useLocation();
  //const isHotelPage = location.pathname.startsWith('/hotel');
  const themeColor = "#ffffff";

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={themeColor} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="msapplication-navbutton-color" content={themeColor} />
      </Helmet>
      <NavBar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/p/:hotelId" element={<Hotel />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/r/:shortUrl" element={<RedirectHandler />} />
        </Routes>
      </div>
      <CustomFooter />
    </>
  );
}

export default App;