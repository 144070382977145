import { MdPool, MdKayaking, MdRestaurantMenu, MdSpa, MdLocalBar, MdSportsGymnastics, MdLocalParking, MdOutlineLocalLaundryService, MdOutlineFastfood, MdOutlineFamilyRestroom, MdOutdoorGrill, MdGroups } from "react-icons/md";
import { TbAirConditioning, TbBeach } from "react-icons/tb";
import { IoIosWifi, IoIosBed, IoIosCafe, IoIosRestaurant } from "react-icons/io";
import { FaShower, FaWater, FaSmoking, FaSmokingBan, FaTree, FaHeart, FaCamera } from "react-icons/fa";
import { LiaIconsSolid, LiaCitySolid } from "react-icons/lia";
import { BiSolidDrink } from "react-icons/bi";
import { PiBowlFoodFill, PiCoatHangerLight, PiBathtubLight } from "react-icons/pi";
import { GiForkKnifeSpoon, GiTowel } from "react-icons/gi";
import { RiSafeLine } from "react-icons/ri";
import { GrShieldSecurity } from "react-icons/gr";
import { LuBaggageClaim, LuPartyPopper} from "react-icons/lu";
import { CiLaptop } from "react-icons/ci";
import { PiAirplaneTiltFill } from "react-icons/pi";

export function AmenityIcon({ type }) {
    switch (type) {
        case "pool":
            return <div style={{ display: 'flex' }}>
                <MdPool style={{ paddingRight: 4, fontSize: 16 }} />
                Pool Access
            </div>
        case "ac":
            return <div style={{ display: 'flex' }}>
                <TbAirConditioning style={{ paddingRight: 4, fontSize: 16 }} />
                Air Conditioning
            </div>
        case "wifi":
            return <div style={{ display: 'flex' }}>
                <IoIosWifi style={{ paddingRight: 4, fontSize: 16 }} />
                Wifi
            </div>
        case "shower":
            return <div style={{ display: 'flex' }}>
                <FaShower style={{ paddingRight: 4, fontSize: 16 }} />
                Private Bathroom
            </div>
        case "kayak":
            return <div style={{ display: 'flex' }}>
                <MdKayaking style={{ paddingRight: 4, fontSize: 16 }} />
                Kayaking
            </div>
        case "room only":
            return <div style={{ display: 'flex' }}>
                <IoIosBed style={{ paddingRight: 4, fontSize: 16 }} />
                Room Only (No Meals)
            </div>
        case "deluxe":
            return <div style={{ display: 'flex' }}>
                <IoIosBed style={{ paddingRight: 4, fontSize: 16 }} />
                Deluxe Room
            </div>
        case "superior":
            return <div style={{ display: 'flex' }}>
                <IoIosBed style={{ paddingRight: 4, fontSize: 16 }} />
                Superior Room
            </div>
        case "standard":
            return <div style={{ display: 'flex' }}>
                <IoIosBed style={{ paddingRight: 4, fontSize: 16 }} />
                Standard Room
            </div>
        case "evening tea":
            return <div style={{ display: 'flex' }}>
                <IoIosCafe style={{ paddingRight: 4, fontSize: 16 }} />
                Evening Tea
            </div>
        case "welcome drink":
            return <div style={{ display: 'flex' }}>
                <BiSolidDrink style={{ paddingRight: 4, fontSize: 16 }} />
                Welcome Drink
            </div>
        case "buffet":
            return <div style={{ display: 'flex' }}>
                <GiForkKnifeSpoon style={{ paddingRight: 4, fontSize: 16 }} />
                Buffet
            </div>
        case "lunch":
            return <div style={{ display: 'flex' }}>
                <PiBowlFoodFill style={{ paddingRight: 4, fontSize: 16 }} />
                Lunch
            </div>
        case "dinner":
            return <div style={{ display: 'flex' }}>
                <PiBowlFoodFill style={{ paddingRight: 4, fontSize: 16 }} />
                Dinner
            </div>
        case "breakfast":
            return <div style={{ display: 'flex' }}>
                <PiBowlFoodFill style={{ paddingRight: 4, fontSize: 16 }} />
                Breakfast
            </div>
        case "full board":
            return <div style={{ display: 'flex' }}>
                <PiBowlFoodFill style={{ paddingRight: 4, fontSize: 16 }} />
                Full Board
            </div>
        case "half board":
            return <div style={{ display: 'flex' }}>
                <PiBowlFoodFill style={{ paddingRight: 4, fontSize: 16 }} />
                Full Board
            </div>
        case "b&b":
            return <div style={{ display: 'flex' }}>
                <PiBowlFoodFill style={{ paddingRight: 4, fontSize: 16 }} />
                Bed & Breakfast
            </div>
        case "public changing room":
            return <div style={{ display: 'flex' }}>
                <PiCoatHangerLight style={{ paddingRight: 4, fontSize: 16 }} />
                Changing Room
            </div>
        case "beach":
            return <div style={{ display: 'flex' }}>
                <TbBeach style={{ paddingRight: 4, fontSize: 16 }} />
                Beach Front
            </div>
        case "city":
            return <div style={{ display: 'flex' }}>
                <LiaCitySolid style={{ paddingRight: 4, fontSize: 16 }} />
                City View
            </div>
        case "garden":
            return <div style={{ display: 'flex' }}>
                <FaTree style={{ paddingRight: 4, fontSize: 16 }} />
                Garden View
            </div>
        case "sea":
            return <div style={{ display: 'flex' }}>
                <FaWater style={{ paddingRight: 4, fontSize: 16 }} />
                Sea View
            </div>
        case "lake":
            return <div style={{ display: 'flex' }}>
                <FaWater style={{ paddingRight: 4, fontSize: 16 }} />
                Lake View
            </div>
        case "smoking":
            return <div style={{ display: 'flex' }}>
                <FaSmoking style={{ paddingRight: 4, fontSize: 16 }} />
                Smoking Room
            </div>
        case "non-smoking":
            return <div style={{ display: 'flex' }}>
                <FaSmokingBan style={{ paddingRight: 4, fontSize: 16 }} />
                Non-Smoking Room
            </div>
        case "tv":
            return <div style={{ display: 'flex' }}>
                <FaWater style={{ paddingRight: 4, fontSize: 16 }} />
                TV
            </div>
        case "toiletries":
            return <div style={{ display: 'flex' }}>
                <GiTowel style={{ paddingRight: 4, fontSize: 16 }} />
                Toiletries
            </div>
        case "bathtub":
            return <div style={{ display: 'flex' }}>
                <PiBathtubLight style={{ paddingRight: 4, fontSize: 16 }} />
                Bathtub
            </div>
        case "locker":
            return <div style={{ display: 'flex' }}>
                <RiSafeLine style={{ paddingRight: 4, fontSize: 16 }} />
                Locker
            </div>
        case "alacarte":
            return <div style={{ display: 'flex' }}>
                <MdRestaurantMenu style={{ paddingRight: 4, fontSize: 16 }} />
                A La Carte
            </div>
        case "spa":
            return <div style={{ display: 'flex' }}>
                <MdSpa style={{ paddingRight: 4, fontSize: 16 }} />
                Wellness Centre
            </div>
        case "bar":
            return <div style={{ display: 'flex' }}>
                <MdLocalBar style={{ paddingRight: 4, fontSize: 16 }} />
                Bar
            </div>
        case "gym":
            return <div style={{ display: 'flex' }}>
                <MdSportsGymnastics style={{ paddingRight: 4, fontSize: 16 }} />
                Gym
            </div>
        case "security":
            return <div style={{ display: 'flex' }}>
                <GrShieldSecurity style={{ paddingRight: 4, fontSize: 16 }} />
                24 x 7 Security
            </div>
        case "parking":
            return <div style={{ display: 'flex' }}>
                <MdLocalParking style={{ paddingRight: 4, fontSize: 16 }} />
                Parking
            </div>
        case "restaurant":
            return <div style={{ display: 'flex' }}>
                <IoIosRestaurant style={{ paddingRight: 4, fontSize: 16 }} />
                Restaurant
            </div>
        case "laundry":
            return <div style={{ display: 'flex' }}>
                <MdOutlineLocalLaundryService style={{ paddingRight: 4, fontSize: 16 }} />
                Laundry Service
            </div>
        case "baggage":
            return <div style={{ display: 'flex' }}>
                <LuBaggageClaim style={{ paddingRight: 4, fontSize: 16 }} />
                Baggage Storage
            </div>
        case "dayacation":
            return <div style={{ display: 'flex' }}>
                <MdOutlineFastfood style={{ paddingRight: 4, fontSize: 16 }} />
                Dayacation
            </div>
        case "work":
            return <div style={{ display: 'flex' }}>
                <CiLaptop style={{ paddingRight: 4, fontSize: 16 }} />
                Work
            </div>
        case "romance":
            return <div style={{ display: 'flex' }}>
                <FaHeart style={{ paddingRight: 4, fontSize: 16 }} />
                Romance
            </div>
        case "layover":
            return <div style={{ display: 'flex' }}>
                <PiAirplaneTiltFill style={{ paddingRight: 4, fontSize: 16 }} />
                Layover/Transit
            </div>
        case "family":
            return <div style={{ display: 'flex' }}>
                <MdOutlineFamilyRestroom style={{ paddingRight: 4, fontSize: 16 }} />
                Family
            </div>
        case "photoshoot":
            return <div style={{ display: 'flex' }}>
                <FaCamera style={{ paddingRight: 4, fontSize: 16 }} />
                Photoshoot
            </div>
        case "picnic":
            return <div style={{ display: 'flex' }}>
                <MdOutdoorGrill style={{ paddingRight: 4, fontSize: 16 }} />
                Picnic
            </div>
        case "group":
            return <div style={{ display: 'flex' }}>
                <MdGroups style={{ paddingRight: 4, fontSize: 16 }} />
                Group
            </div>
        case "friendventure":
            return <div style={{ display: 'flex' }}>
                <MdGroups style={{ paddingRight: 4, fontSize: 16 }} />
                Friendventure
            </div>
        case "wellness":
            return <div style={{ display: 'flex' }}>
                <MdSpa style={{ paddingRight: 4, fontSize: 16 }} />
                Wellness
            </div>
        case "bachelorette":
            return <div style={{ display: 'flex' }}>
                <LuPartyPopper style={{ paddingRight: 4, fontSize: 16 }} />
                Bachelorette
            </div>
        default:
            return <div style={{ display: 'flex' }}>
                <LiaIconsSolid style={{ paddingRight: 4, fontSize: 16 }} />
                {type}
            </div>
    }
}