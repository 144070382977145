import React from 'react';
import { Col, Row } from 'antd';
import { FaSearch, FaBook, FaMoneyBillWave } from 'react-icons/fa';
import './index.css';
import CustomIcon from '../../utils/customIcon.js';
import { useNavigate } from 'react-router-dom';

const DayUseCard = ({ image, title, link }) => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    if (typeof link === 'function') {
      link();
    } else {
      navigate(link);
    }
  };

  return (
    <Col xs={24} sm={12} md={12} lg={6} className="day-use-card-col">
      <div className="day-use-card" onClick={handleClick}>
        <img alt={title} src={image} />
        <div className="description">
          <h3 className="title">{title}</h3>
        </div>
      </div>
    </Col>
  );
};

const StepsCard = ({ icon: Icon, title, link }) => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    if (typeof link === 'function') {
      link();
    } else {
      navigate(link);
    }
  };

  return (
    <Col xs={24} sm={12} md={8} lg={8} className="steps-card-col">
      <div className="steps-card" onClick={handleClick}>
        <Icon size={80} className="icon" />
        <div className="description">
          <h3 className="title">{title}</h3>
        </div>
      </div>
    </Col>
  );
};

const DayUse = () => {
  const navigate = useNavigate();
  
  const handleViewAllDayacation = () => {
    navigate('/search?package=dayout&option=friendventure');
  };

  const handleViewAllRomance = () => {
    navigate('/search?package=dayout&option=romance');
  };

  const handleViewAllWork = () => {
    navigate('/search?package=dayout&option=work');
  };

  const handleViewAllLayover = () => {
    navigate('/search?package=dayout&option=layover');
  };

  const dayUseItems = [
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Fromance.png?alt=media&token=163fb76a-84a6-4da4-bd28-b10b6dbab784',
      title: 'Romantic Getaway',
      link: handleViewAllRomance
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Fdayacation.png?alt=media&token=e44369de-2e6b-42e4-8ec5-bbf0d7c72eed',
      title: 'Friendventure',
      link: handleViewAllDayacation
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Fwork.webp?alt=media&token=7d5eb721-7760-4807-ab43-dc658717a578',
      title: 'Work in Luxury',
      link: handleViewAllWork
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Ftransit.webp?alt=media&token=c0ebf877-907f-461e-b00a-8e3a8119c593',
      title: 'Layover/Transit',
      link: handleViewAllLayover
    }
  ];

  const options = ["Bachelorette", "Business", "Layover", "Meeting", "Photoshoot", "Picnic", "Romance", "Wellness", "And..."];
  
  const handleOptionClick = (option) => {
    let searchOption = option.toLowerCase();
    if (option === "Business" || option === "Meeting") {
      navigate(`/search?package=dayout&option=work`);
    } else if (option === "And...") {
      navigate('/search?package=dayout');
    } else {
      navigate(`/search?package=dayout&option=${searchOption}`);
    }
  };

  return (
    <div className="widget-block">
      <div className="widget-heading">
        <h2 className="title">Curating Experiences Harmonized with Your Desires</h2>
        <div className="custom-icon">
          <div className="tags">
            {options.map((option, index) => (
              <CustomIcon name={option} key={index} onClick={() => handleOptionClick(option)} />
            ))}
          </div>
        </div>
      </div>
      <Row gutter={[20, 20]} className="widget-content">
        {dayUseItems.map((item, index) => (
          <DayUseCard key={index} image={item.image} title={item.title} link={item.link} />
        ))}
      </Row>
    </div>
  );
};

const Steps = () => {
  const navigate = useNavigate();

  const handleViewSearch = () => {
    navigate('/search');
  };

  const stepsItems = [
    {
      icon: FaSearch,
      title: 'Search: Discover your dream getaway',
      link: handleViewSearch
    },
    {
      icon: FaBook,
      title: 'Book: Reserve your desired package',
      link: handleViewSearch
    },
    {
      icon: FaMoneyBillWave,
      title: 'Pay: Settle on-site and enjoy',
      link: handleViewSearch
    },
  ];

  return (
    <div className="widget-block">
      <div className="widget-heading">
        <h2 className="title">Your Guide to Zeylon</h2>
      </div>
      <Row gutter={[20, 20]} className="widget-content">
        {stepsItems.map((item, index) => (
          <StepsCard key={index} icon={item.icon} title={item.title} link={item.link} />
        ))}
      </Row>
    </div>
  );
};

export { DayUse, Steps };